<template>
<div>
  <h4 class="mb-2">Elige las opciones del campo</h4>
  <v-col>
    <v-row class="ml-3 align-center">
      <v-text-field
        v-model="textFieldData"
        label="Ingresa una opción"
        append-icon="mdi-plus-box"
        @click:append="insertRow()">
      </v-text-field>
    </v-row>

    <v-data-table
      class="mb-10"
      :headers="optionsHeader"
      hide-default-header
      :items="options">
      <template v-slot:item.buttons="{ item }">
        <v-row justify="end">
          <v-icon @click="removeRow(item)">mdi-delete</v-icon>
        </v-row>
      </template>
    </v-data-table>
    <v-checkbox
    label="¿Quieres agregar la opción de OTRO?"
    v-model="addOtherOption">
    </v-checkbox>
  </v-col>

</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  FIELDS_GET_FIELD_OPTIONS,
  FIELDS_CREATE_FIELD_OPTION,
  FIELDS_REMOVE_FIELD_OPTION,
  FIELDS_GET_FIELD
} from '@/store/actions.type'

export default {
  name: 'field-options',
  data() {
    return {
      textFieldData: '',
      addOtherOption: false,
      optionsHeader: [
        { text: 'Value', value: 'value' },
        { text: 'Buttons', value: 'buttons' }
      ]
    }
  },
  methods: {
    ...mapActions({
      getOptions: FIELDS_GET_FIELD_OPTIONS,
      createFieldOption: FIELDS_CREATE_FIELD_OPTION,
      removeFieldOption: FIELDS_REMOVE_FIELD_OPTION,
      getField: FIELDS_GET_FIELD
    }),
    insertRow() {
      const index = this.options.findIndex(o => o.value === this.textFieldData)
      if (index < 0) {
        const fieldId = this.fieldId
        const value = this.textFieldData
        this.createFieldOption({ fieldId, value })
      }
      this.textFieldData = ''
    },
    removeRow(option) {
      const fieldId = this.fieldId
      this.removeFieldOption({ fieldId, option })
    },
    goToList() {
      this.$router.push('/settings/fields')
    }
  },
  computed: {
    ...mapGetters({
      options: 'getFieldOptions'
    }),
    fieldId() {
      return this.$route.params.id
    }
  },
  created() {
    this.getField(this.fieldId)
    this.getOptions(this.fieldId)
  }
}
</script>
<style scoped>
</style>
