<template>
  <div>
    <title-header :title="headerText"></title-header>
    <v-form v-model="validData" class="ml-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              label="*Escribe tu pregunta"
              v-model="field.name"
              :rules="rules.name"
            ></v-text-field>
            <v-text-field
              outlined
              label="*Escribe la descripción"
              v-model="field.description"
              :rules="rules.name"
            ></v-text-field>
            <v-select
            outlined
              :items="fieldTypes"
              v-model="field.fieldType"
              item-text="name"
              item-value="slug"
              label="Tipo"
            ></v-select>
            <div v-if="hasValidations && field.fieldType.slug != 'date'">
              <v-row>
                <v-col md="6">
                  <v-text-field outlined label="Escribe el valor minimo de caracteres" type="number" v-model.number="field.validations.minLetters"
                    :rules="[( this.field.validations.minLetters >= 0) || 'No puede ser menor a 0']" />
                </v-col>
                <v-col md="6">
                  <v-text-field outlined label="Escribe el valor máximo de caracteres" type="number" v-model.number="field.validations.maxLetters"
                    :rules="[( this.field.validations.maxLetters >= 0) || 'No puede ser menor a 0']" />
                </v-col>
              </v-row>
              <v-text-field outlined label="Escribe el campo de validación (Ejem. ^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$)" v-model="field.validations.regexText" />
              <v-switch v-model="field.validations.onlyCapitalLetters" label="Asignar todo a mayusculas"></v-switch>
              <p class="ml-2">
                <small class="text-subtitle-3">*Al aceptar esta opción, se forzará a que el campo a crear solo acepte letras en mayúsculas</small>
              </p>
            </div>
            <div v-else-if="hasValidations && field.fieldType.slug == 'date'">
              <v-row>
                <v-col md="5">
                  <v-menu
                    ref="menu-date-min"
                    v-model="menuDateMin"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="dateMinRules"
                        outlined
                        ref="pickerMin"
                        v-model="MinDate"
                        label="Fecha mínima"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="pickerDateMin"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      show-current="true"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="5">
                  <v-menu
                    ref="menu-date-max"
                    v-model="menuDateMax"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :rules="dateMaxRules"
                        ref="pickerMax"
                        outlined
                        v-model="MaxDate"
                        label="Fecha máxima"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :disabled="field.validations.isCurrentDateMaxDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="pickerDateMax"
                      show-current="true"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="2">
                  <v-btn
                    @click="reset"
                    class="mx-2 mt-2"
                    fab
                    dark
                    small
                    color="primary"
                  >
                  <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-switch v-model="field.validations.isCurrentDateMaxDate" ref="currentDateMax" @change="isActive" :rules="validSwitchRule" label="¿Deseas limitar la fecha máxima de la pregunta al día actual en la aplicación al momento de realizar la encuesta?"></v-switch>
              <p class="ml-2">
                <small class="text-subtitle-3">*En la aplicación saldrá la fecha máxima del día que se realice la pregunta</small>
              </p>
            </div>
            <v-select
              outlined
              :items="issueTypes"
              v-model="field.issueType"
              item-text="name"
              item-value="id"
              label="Tipo de problema"
            ></v-select>
            <p class="ml-2">
              <small>*Campos obligatarios</small>
            </p>
            <v-btn outlined rounded color="primary" dark @click="cancel()">Cancelar</v-btn>
            <v-btn
              class="ml-6"
              rounded
              color="primary"
              @click="updateFieldClicked()"
              :disabled="!validData"
            >Actualizar</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <field-options v-if="isComplexField"></field-options>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">Felicidades</v-card-title>
        <v-card-text>El campo se ha actualizado satisfactoriamente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import FieldOptions from '@/components/app/fields/field-options'
import TitleHeader from '@/components/app/title-header/title-header'
import ComplexFields from '@/constants/complex-fields'
import rules from '@/constants/input-validation'
import {
  FIELDS_GET_FIELD,
  FIELDS_GET_FIELD_TYPES,
  FIELDS_UPDATE_FIELD,
  ISSUE_TYPES_GET_ISSUE_TYPES
} from '@/store/actions.type'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    FieldOptions,
    TitleHeader
  },
  watch: {
    pickerDateMin() {
      this.field.validations.minDate = this.pickerDateMin
    },
    pickerDateMax() {
      this.field.validations.maxDate = this.pickerDateMax
    }
  },
  methods: {
    ...mapActions({
      getField: FIELDS_GET_FIELD,
      getFieldTypes: FIELDS_GET_FIELD_TYPES,
      updateField: FIELDS_UPDATE_FIELD,
      getIssuesTypes: ISSUE_TYPES_GET_ISSUE_TYPES
    }),
    reset() {
      this.$refs.pickerMax.reset()
      this.$refs.pickerMin.reset()
      this.field.validations.maxDate = null
      this.field.validations.minDate = null
      this.validData = true
    },
    cancel() {
      this.$router.push('/settings/fields')
    },
    isActive() {
      if (this.field.validations.isCurrentDateMaxDate === true) {
        this.$refs.pickerMax.reset()
        this.field.validations.maxDate = null
        this.validData = true
      }
    },
    buttonDisable() {
      if (this.field.validations.maxDate === null && this.field.validations.minDate === null) {
        this.validData = true
      }
    },
    updateFieldClicked() {
      if (this.field && this.field.validations) {
        if (this.field.validations.minLetters === '' || this.field.validations.maxLetters === '') {
          this.field.validations.minLetters = null
          this.field.validations.maxLetters = null
        }
        if (this.field.validations.maxDate === '' || this.field.validations.minDate === '') {
          this.field.validations.maxDate = null
          this.field.validations.minDate = null
        }
        if (this.isCurrentDateMaxDate) {
          this.field.validations.isCurrentDateMaxDate = true
        }
        if (this.allInUppercase) {
          this.field.validations.onlyCapitalLetters = true
        }
      }

      this.updateField(this.field)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e
          this.showSnackbar = true
        })
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      field: 'getSelectedField',
      fieldTypes: 'getFieldTypes',
      issueTypes: 'getIssueTypesIssueTypes'
    }),
    MaxDate() {
      if (this.field.validations.maxDate) {
        const date = this.field.validations.maxDate
        const arrayDate = date.split('T')
        const newPositionDate = arrayDate[0]
        const arrDate = newPositionDate.split('-')
        const fullYear = arrDate[0]
        const mounth = arrDate[1]
        const day = arrDate[2]
        const formatDate = day + ' / ' + mounth + ' / ' + fullYear

        return formatDate
      } else {
        return null
      }
    },
    MinDate() {
      if (this.field.validations.minDate) {
        const date = this.field.validations.minDate
        const arrayDate = date.split('T')
        const newPositionDate = arrayDate[0]
        const arrDate = newPositionDate.split('-')
        const fullYear = arrDate[0]
        const mounth = arrDate[1]
        const day = arrDate[2]
        const formatDate = day + ' / ' + mounth + ' / ' + fullYear

        return formatDate
      } else {
        return null
      }
    },
    isComplexField() {
      if (this.field && this.field.fieldType) {
        return ComplexFields.includes(this.field.fieldType.slug)
      } else {
        return null
      }
    },
    hasValidations() {
      if (this.field && this.field.fieldType) {
        return this.field.fieldType.hasValidations
      } else {
        return null
      }
    }
  },
  created() {
    this.getField(this.$route.params.id)
    this.getFieldTypes()
    this.getIssuesTypes()
  },
  data() {
    return {
      headerText: 'Editar campo',
      minLetters: null,
      dateFormatedMin: '',
      isCurrentDateMaxDate: false,
      allInUppercase: false,
      validData: false,
      dialog: false,
      showSnackbar: false,
      menuDateMin: false,
      menuDateMax: false,
      snackBarText: '',
      pickerDate: new Date().toISOString().substr(0, 10),
      pickerDateMax: new Date().toISOString().substr(0, 10),
      pickerDateMin: new Date().toISOString().substr(0, 10),
      dateMinRules: [
        v => (v.length > 0 && this.pickerDateMin > this.pickerDateMax) ? 'La fecha mínima no puede ser mayor a la máxima' : true
      ],
      dateMaxRules: [
        v => (v.length > 0 && this.pickerDateMax < this.pickerDateMin) ? 'La fecha máxima no puede ser menor a la mínima o incluso anterior al del dia de hoy' : true
      ],
      validSwitchRule: [
        v => (v.length > 0 && this.field.validations.isCurrentDateMaxDate === true) ? this.validData : true
      ],
      rules
    }
  }
}
</script>
